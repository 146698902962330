import {LitElement, html, css, nothing} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';
import {ChatLog} from "../models/chat-log";
import {MenuItem} from "../models/menu-item";
import {ChatLogController} from "../controllers/chat-log-controller";
import {toRelativeTime} from "../utils";
import {SlDialog} from "@shoelace-style/shoelace";
import {AuthRedirect} from '../auth/authRedirect';


@customElement('chat-menu')
export class ChatMenu extends LitElement {
  static override styles = css`
    .overlay {
      flex: 1;
      display: flex;
      flex-direction: row;
      background: #f9f9f9;
      height: 100%;
    }

    .chat-container {
      width: 100%;
    }

    .menu-container {
      background-color: #E9E9E6;
      min-width: 290px;
      width: 30vw;
      min-height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .header {
      padding-top: 24px;
      padding-left: 32px;
      position: relative;
      margin-bottom: 40px;
    }

    .chat-button-container {
      position: relative;
      text-align: center;
    }

    .menu-item-container {
      padding-bottom: 16px;
    }

    .menu-item-base {
      padding: 8px 24px 8px 24px;
      gap: 10px;
      font-size: 14px;
      line-height: 22.4px;
      display: flex;
      justify-content: space-between;
    }

    .menu-label {
      color: var(--gray-300);
      font-weight: 500;
      line-height: 22.4px;
    }

    .menu-item {
      color: var(--gray-300);
      font-weight: 450;
    }

    .menu-item:hover {
      cursor: pointer;
      font-weight: 450;
      background-color: #0000000D;
    }

    .menu-item-selected {
      background-color: #0000000D;
    }

    .menu-item-collapsed {
      visibility: collapse;
    }

    sl-icon.new-chat-icon::part(base) {
      color: var(--orange-300);
      font-weight: 500;
    }

    sl-button.new-chat-button::part(base) {
      font-size: 16px;
      font-weight: 500;
      color: var(--orange-300);
      background-color: transparent;
      border: 1px solid var(--orange-300);
      border-radius: 8px;
      gap: 8px;
      width: 242px;
      height: 40px;
      margin-bottom: 20px;
    }

    sl-button.new-chat-button::part(base):hover {
      background-color: var(--orange-300);
      color: var(--white);
    }

    sl-button.cancel-button::part(base) {
      font-size: 16px;
      font-weight: 500;
      background-color: transparent;
      color: var(--gray-300);
      border-width: 0;
    }

    sl-button.cancel-button::part(base):hover {
      color: var(--orange-300);
    }

    sl-button.confirm-delete-button::part(base) {
      font-size: 16px;
      font-weight: 500;
      background-color: var(--orange-300);
      color: var(--white);
      border-width: 0;
    }

    sl-button.confirm-delete-button::part(base):hover {
      background-color: var(--orange-400);
      color: var(--white);
    }
    
    .logout-container {
      position: absolute;
      bottom: 12px;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    sl-button.logout-button::part(base) {
      font-size: 14px;
      font-weight: 500;
      color: var(--gray-300);
      background-color: transparent;
      border: 1px solid rgba(98, 111, 120, 0.25);
      border-radius: 8px;
      gap: 8px;
      width: 242px;
      height: 36px;
      margin-bottom: 20px;
    }

    sl-button.logout-button::part(base):hover {
      background-color: #0000000D;
    }
  `;

  private readonly defaultChatName = "My new chat";

  @property({type: Boolean}) isEditMode: boolean = false;
  @property({type: String}) newChatName: string = this.defaultChatName;

  @query('#newChatInput') private newChatInput?: HTMLElement;
  @query('#deleteChatDialog') private deleteDialog?: SlDialog;

  @state() private chatLogController = new ChatLogController(this);

  override updated(): void {
    this.updateComplete.then(() => {
      this.newChatInput?.focus();
    });
  }

  override render() {
    return html`
        <div class="overlay">
          <div class="menu-container">
              <div class="header">
                  <img src='../../static/images/northell-logo.svg' alt='Northell Logo'>
              </div>
              <div class="chat-button-container">
                <sl-button class="new-chat-button" variant="default" size="medium" @click="${this.addNewChat}">
                    <sl-icon class="new-chat-icon" slot="prefix" name="plus-lg"></sl-icon>
                    Start new chat
                </sl-button>
              </div>
              <div class="chat-list-container">
                  ${this.renderNewMessageInput()}
                  ${this.chatLogController.chat?.chatHistory.map((item) => this.renderMenuItem(item))}
              </div>
              <div class="logout-container">
                <sl-button class="logout-button" variant="default" size="medium" @click="${this.logOut}">
                  Log out
                  <sl-icon class="logout-icon" slot="suffix" name="box-arrow-right"></sl-icon>
                </sl-button>
              </div>
          </div>
            ${this.renderChat()}
        </div>
        <sl-dialog id="deleteChatDialog" class="dialog-overview menu-label">
            <div slot="label" class="menu-label">
                Delete chat
            </div>
            <div class="menu-item">
                Are you sure you want to delete this chat?
            </div>
            <sl-button class="cancel-button" slot="footer" variant="default" @click="${this.hideDeleteDialog}">Cancel</sl-button>
            <sl-button class="confirm-delete-button" slot="footer" variant="primary" @click="${this.deleteChat}">Delete</sl-button>
        </sl-dialog>
    `;
  }

  renderChat() {
    if (this.chatLogController.selectedMenuItem != null) {
      return html`
          <chat-container
                  class="chat-container"
                  chatId="${this.chatLogController.selectedMenuItem?.id}"
                  chatTitle="${this.chatLogController.selectedMenuItem?.title}">
          </chat-container>
        `;
      }

      return nothing;
    }

  renderMenuItem(chatLog: ChatLog) {
    return html`
      <div class="menu-item-container">
        <div class="menu-item-base menu-label">
            <b>${chatLog.relativeDate}</b>
        </div>
        <div class="menu-items">
            ${chatLog.menuItems.map(item => this.renderMenuSubItem(item))}
        </div>
      </div>
    `;
  }

  renderMenuSubItem(menuItem: MenuItem) {
    return html`
      <div class="menu-item-base menu-item ${menuItem.isSelected ? 'menu-item-selected' : ''}"
           @click="${() => this.selectChat(menuItem)}">
          <div>
              ${menuItem.title}
          </div>
          <div class="${menuItem.isSelected === false ? 'menu-item-collapsed' : ''}" @click="${this.showDeleteDialog}">
              <sl-icon name="trash"></sl-icon>
          </div>
      </div>
    `;
  }

  renderNewMessageInput() {
    if (this.isEditMode) {
      return html`
          <div class="menu-item-container">
              <div class="menu-item-base menu-label">
                  <b>${toRelativeTime(new Date())}</b>
              </div>
              <div class="menu-item-base menu-item">
                  <form @submit='${this.submitNewChat}'>
                      <sl-input id="newChatInput" @input="${this.changeChatName}"
                                @sl-blur="${this.resetEditMode}"></sl-input>
                  </form>
              </div>
          </div>
      `;
    }

    return nothing;
  }

  changeChatName(event: any) {
    const input = event.target;
    this.newChatName = input?.value;
  }

  private addNewChat() {
    this.isEditMode = true;
  }

  private async submitNewChat(event: SubmitEvent): Promise<void> {
    event.preventDefault();
    await this.chatLogController.saveNewChat(this.newChatName);
    await this.chatLogController.fetchChatLog();
    this.resetEditMode()
  }

  private resetEditMode(): void {
    this.newChatName = this.defaultChatName;
    this.isEditMode = false;
  }

  private selectChat(menuItem: MenuItem): void {
    this.chatLogController.chat?.chatHistory.forEach(log => {
      log.menuItems.forEach(item => {
        item.isSelected = false;
      });
    });

    menuItem.isSelected = true;
    this.chatLogController.selectedMenuItem = menuItem;
    this.requestUpdate();
  }

  private showDeleteDialog(): void {
    this.deleteDialog?.show()
  }

  private hideDeleteDialog(): void {
    this.deleteDialog?.hide()
  }

  private async deleteChat(): Promise<void> {
    await this.chatLogController.deleteChat();
    this.hideDeleteDialog();
  }

  private async logOut(): Promise<void> {
    AuthRedirect.logout();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'chat-menu': ChatMenu;
  }
}
